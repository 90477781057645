body,
html,
#root {
  height: 100%;
}

:focus {
  outline: none;
}

.MuiCardActions-root {
  border-top: 1px solid#ccc;
}

.MuiIconButton-root {
  padding: 5px !important;
}

.MuiCardHeader-title {
  font-size: 1rem !important;
}

.MuiCardHeader-subheader {
  font-size: 0.8rem !important;
}
.MuiList-padding {
  padding: 0 !important;
}

.positiveTrend {
  font-weight: bold;
  color: #4caf50;
}
.negativeTrend {
  font-weight: bold;
  color: #f44336;
}

.MuiSvgIcon-root {
  vertical-align: middle;
}

.dateRange {
  font-size: 0.7rem;
  color: #999;
}

.MuiTabs-indicator {
  top: 0 !important;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #000 !important;
}

.DayPicker {
  display: block !important;
}
.Selectable {
  padding: 0px !important;
}

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #cae7ff !important;
  color: #4a90e2;
}

.DayPicker-Day--start,
.DayPicker-Day--end {
  background-color: #cae7ff !important;
  color: #4a90e2 !important;
}

.DayPicker-Caption {
  padding: 0 !important;
  > div {
    font-size: 14px !important;
  }
}

.DayPicker-Weekday {
  font-size: 12px !important;
  background: #f0f0f0;
  color: #666 !important;
}
.DayPicker-Month {
  margin: 0 !important;
}
.DayPicker-Week {
  border-top: 4px solid #fff;
}
.DayPicker-Day {
  border-radius: 0 !important;
  outline: none;
  font-size: 12px;
}

.DayPicker-Day--outside {
  color: #ffffff !important;
  background: #fff !important;
  cursor: default;
}

.DayPicker-NavButton {
  top: 0 !important;
  right: 0 !important;
  margin-top: 0px !important;
}

.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
